
<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        elevation="12"

          v-bind:color="comment.text? 'primary' : 'grey lighten-3'"
          fab x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="!comment.text" color="grey darken-3">mdi-comment</v-icon><v-icon v-else>mdi-comment-text</v-icon>
        </v-btn>
      </template>

      <v-card>
        <div class="text-right">
<v-btn @click="menu=false" icon class="ma-0"><v-icon>mdi-close</v-icon></v-btn>
</div>
<v-divider/>
<v-card-text>
    <v-textarea v-model="commentCopy.text" label="Региональные особенности" hide-details outlined></v-textarea>
</v-card-text>
<v-divider/>
        <v-card-actions>
            <v-btn
             v-if=" JSON.stringify(commentCopy) != JSON.stringify(comment) && !saving"
            text
            @click="cancel()"
          >
            Отменить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="saving"
            @click="save()"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>

<script>
export default {
    props:['comment','type'],
    data(){
        return {
            commentCopy:{},
            menu: false,
            saving:false
        }
    },
    methods:{
        
        cancel(){
            this.commentCopy = JSON.parse(JSON.stringify(this.comment))
            this.menu=false
        },
        save(){
            this.saving=true
            this.$http.post('/employer/comment-update',this.commentCopy, {
                params:{
                    type:this.type,
                    targetId:this.comment.targetId,
                }
            }).then((res)=>{
                this.$emit('comment-update', res.data)
            }).finally(()=>{
                this.menu=false
                this.saving=false
            })
            
        }
    },
    mounted(){
        this.commentCopy = JSON.parse(JSON.stringify(this.comment))
    }
}
</script>

<style>

</style>