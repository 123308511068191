<template>
    <div>
      <v-overlay absolute v-if="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div v-if="!loading">
        <v-row>
          <v-col cols="1">
            <v-sheet outlined rounded class="pa-1" style="position: sticky; top: 12px">{{ profStand.name }}</v-sheet>
          </v-col>
          <v-col cols="11" class="pa-0">
            <v-sheet outlined class="my-3 py-2 ml-0 pr-3" v-for="generalWorkFunction in profStand.generalWorkFunctions"
              :key="'gf' + generalWorkFunction.id">
              <v-row class="ma-0">
                <v-col cols="2" class="py-1 pr-0">
                  <v-sheet outlined style="position: sticky; top: 12px" rounded class="pa-2">
                    <b>ОТФ:</b> {{ generalWorkFunction.name }}
                  </v-sheet>
                </v-col>
                <v-col cols="10" class="pa-0">
                  <v-row class="ma-0" v-for="workFunction in generalWorkFunction.workFunctions"
                    :key="'wf' + workFunction.id">
                    <v-col cols="3" class="py-1 pr-0">
                      <v-sheet style="position: sticky; top: 12px" elevation="1" outlined rounded
                        class="pa-2 d-flex justify-space-between deep-purple lighten-4">
                        <div><b>ТФ:</b> {{ workFunction.name }}
                        <br><div class="text-right"><comment  @comment-update="commentUpdate($event)" :comment="getComment('workFunction',workFunction)" type="workFunction"/></div>
                           
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-row class="ma-0" v-for="workAction in workFunction.workActions" :key="'wa' + workAction.id">
                        <v-col cols="5" class="py-1">
                          <v-sheet style="position: sticky; top: 12px" outlined rounded class="pa-2 orange lighten-4"
                            :elevation="1">
                            <b>ТД:</b> {{ workAction.name }}
                           <br>
                           <div class="text-right"><comment @comment-update="commentUpdate($event)" :comment="getComment('workAction',workAction)" type="workAction"/></div>
                          </v-sheet>
                        </v-col>
                        <v-col cols="7" class="pa-0">
                          <v-sheet :elevation="1" outlined rounded class="pa-2 my-1"
                                   :class="skill.type == 'um' ? 'green lighten-4' : 'blue lighten-4'"
                            v-for="skill in workAction.skills" :key="'s' + skill.id" >
                            <b>{{skill.type == 'um' ? 'У: ' : 'З: ' }}</b> {{ skill.name }}
                          </v-sheet>
  
<!--                          <v-sheet :elevation="1" outlined rounded class="pa-2 my-1 blue lighten-4"-->
<!--                            v-for="knowledge in workAction.knowledges" :key="'k' + knowledge.id">-->
<!--                            <b>З:</b> {{ knowledge.name }}-->
<!--                          </v-sheet>-->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
    import comment from './comment.vue'
  export default {
    props: ["eduProgProfStandId"],
    data() {
      return {
        profStand: {},
        loading: false,
        comments:[]
      };
    },
    methods: {
        commentUpdate(data){
            let index=this.comments.findIndex(el=>el.id==data.id)
            if(index===-1){
                this.comments.push(data)
            } else {
                this.$set(this.comments,index,data)
            }
        },
        getComment(type,target){
            let comment = this.comments.find(el=>el.targetId==target.id && type==type)
            if(!comment){
                comment={text:'',targetId:target.id}
            }
            return comment
        },
      fetchData() {
        this.loading = true;
        this.$http
          .get("employer/prof-stand", {
            params: {
              eduProgProfStandId: this.eduProgProfStandId,
            },
          })
          .then((response) => {
            this.profStand = response.data.profStand;
            this.comments = response.data.comments;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    components:{
        comment
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  
  <style>
  </style>
